import { ROUTES_PATH } from './routes';

export * from './routes';
export * from './actionSheetConstant';
export * from './actionTypes';
export * from './apiEndPoints';
export * from './Tabs';
export * from './swiperConfig';
export const IDENTIFIERS = 'com.ackertinc.pipeline';
export const TIMEZONE = 'America/Los_Angeles';
export const ROUTES_TYPE = {
  TARGET: 'target',
  INSIGHTS: 'insights',
};

export const daysAndWeekLabel = {
  1: 'tomorrow',
  '-1': 'yesterday',
  '-8': 'last week',
  8: 'next week',
  0: 'today',
};

export const filterLabel = {
  standing: {
    total: 'All Target',
    overdue: 'Overdue',
    soon: 'Due Soon',
    later: 'Due Later',
  },
  value: {
    L: 'Low Value',
    M: 'Medium Value',
    H: 'High Value',
  },
  type: {
    client: 'Client',
    prospect: 'Prospect',
    refsource: 'Referral Source',
  },
};
export const sortingOrder = {
  // contact sorting is A -> Z
  contact: {
    lower: 1,
    upper: -1,
  },
  // company sorting is A -> Z
  company: {
    lower: 1,
    upper: -1,
  },
};
export const buckets = {
  soon: 'soon',
  overdue: 'overdue',
  later: 'later',
};
export const MESSAGES = {
  invalidUsername: 'Invalid username or password',
  UnknownError: 'Oops! Something went wrong.',
  syncError: 'We are unable to sync with the server.',
  noRevenue: 'Please enter a $ amount.',
  requiredRevenue: 'Please enter revenue.',
  alreadyShared: 'You have already shared this target with username.',
  currentPwdError: 'Current password is not correct.',
  changePwdFailed: 'Change Password failed!',
  changePwdSuccess: 'Your Password has been changed successfully.',
  saveWorksheet: 'Your Worksheet has been submitted successfully.',
  NETWORKERR: 'Network Error',
  NOINTERNET: 'There is no internet connection.',
  INFOUPDATE: 'Your account has been updated.',
  DELETEDTRASH: 'Your deleted targets were successfully removed.',
  TARGETRESCIND: 'user will no longer be able to collaborate on this Target. Are you sure?',
  TARGETWITHDRAW: 'You will no longer be able to collaborate on this Target. Are you sure?',
  EDITCHANGESCONFIRM: 'You have made changes to this Target. Do you want to save them first?',
  PWDCHANGE: 'Your Password has been changed.',
  SETTINGUPDATE: 'Notification setting has been updated.',
  SWITCHEDUSER: 'Successfully switched to ',
  OTP_RESEND_ERROR: 'There is a problem resending the Verification code. Please wait a few seconds and try again.',
  NO_INSIGHT_FOUND: 'No insight found with the specific id.',
  NO_TARGET_FOUND: 'No target found with the specific id.',
  NO_LESSON_FOUND: 'No lesson found with the specific id.',
};

export const targetDetailsAction = {
  snooze: 'snooze',
  next: 'next',
  cancel: '',
  delete: 'delete',
  complete: 'complete',
  edit: 'edit',
  lost: 'lost',
};
export const tabs = ['Notes', 'Activity', 'Collaboration'];
export const taskType = { done: 'done', incomplete: 'incomplete' };
export const sharedStatus = {
  pending: 'pending',
  notShared: 'no',
  revoked: 'revoked',
  rejected: 'rejected',
  accepted: 'accepted',
};
export const loaderArray = [1, 2, 3, 4, 5, 6, 7, 8];
export const maxBadgeCount = 99;
export const taskFilter = { active: 'incomplete', archived: 'incomplete', completed: 'done' };
export const firebaseConfig = {
  messagingSenderId: '182342586546',
  apiKey: 'AIzaSyCwZndsJkCX4empj7NWL2M7o_r7nXDQZgY',
  authDomain: 'pipeline-plus-ackert.firebaseio.com',
  databaseURL: 'https://pipeline-plus-ackert.firebaseio.com/',
  projectId: 'pipeline-plus-ackert',
  storageBucket: 'pipeline-plus-ackert.appspot.com',
};
export const nextWeek = 8;
export const tomorrow = 1;
export const ESCAPEKEY = 27;
export const ENTERKEY = 13;
export const yes = 'yes';
export const no = 'no';
export const rescindinvite = 'Cancel Invitation';
export const rescind = 'Remove';
export const withdraw = 'Withdraw';
export const outsideSlideClass =
  'app swiper-button-next swiper-button-prev cancel save action-item title action-sheet inner action-cancel react-autosuggest__suggestion';
export const branchFeature = {
  RESETPWD: 'Reset Password',
  TARGETDETAIL: 'Target Detail',
};
export const DueDateFormatToDisplay = 'MMM D, YYYY';
export const DueDateFormatForAPI = 'YYYY-MM-DD';
export const syncDateFormat = 'YYYY-MM-DD HH:mm:ss';
export const ALL = 'all';
export const SWIPER_NAV_NAME = {
  insight: { title: 'Insights', path: ROUTES_PATH.INSIGHTS },
  target: { title: 'Targets', path: ROUTES_PATH.DASHBOARD },
  viewer: { title: 'Dashboard', path: ROUTES_PATH.VIEWER },
  trainer: { title: 'Lessons', path: ROUTES_PATH.TRAINER },
};
export const reactive = 'reactivated';
export const defaultTaskDescription = 'Identify next steps';
export const dummyFiles = {
  pdf: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
  csv: 'http://samplecsvs.s3.amazonaws.com/TechCrunchcontinentalUSA.csv',
};
export const REPORT_CASES = { GROUPS: 'groups', ORG: 'org' };
export const REPORT_CASES_KEYVALUE = { org: 'org', group: 'groups', person: 'members' };
export const REPORT_TITLE = { org: 'Firm', groups: 'Group' };
export const GROUP = 'group';

/* we don't want to sort what comes back from the server
export const LESSONS_ORDER = [
    "Orientation", "Initial Assessment", "Branding", "Prospecting",
    "Sales", "Midterm Review", "Networking", "Time Management",
    "Social Media", "Client Service", "Final Assessment"
];
*/

export const RESOURCE_TYPES = {
  VIDEO: 'video',
  AUDIO: 'audio',
  BLOG: 'blog',
};

export const VIEWER_STATIC_CONTENT = {
  overview: {
    title: 'Overview',
  },
  highlights: {
    title: 'Highlights',
  },
  targets: {
    title: 'Targets & Collaborations',
  },
  clients: {
    title: 'Current Clients',
  },
  overlaps: {
    title: 'Current Overlaps',
  },
  progress: {
    title: 'Progress',
  },
  worksheets: {
    title: 'Worksheets',
  },
};
export const RESEND_CODE_TIMEOUT = 1;
export const CHALLENGE_ERROR_CODE = {
  MISSING: 'challenge_missing',
};

export const TILES_STATUS = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  COMPLETED: 'completed',
  LOST: 'lost',
};
