import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ReportTypes } from '../../../components';
import { REPORT_CASES } from '../../../constant';
import { resetPeopleTargets } from '../../../actions';

// Sidebar container to wrap all the components avalible on sidebar
class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      people: [],
      sidebarOpened: false,
    };
    this.key = '';
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      match: { url },
    } = this.props;
    const splitParams = (pathname) => pathname.split(`${url}/people/`)[1];

    if (this.props.viewer.graphOf.id && this.key !== this.props.viewer.graphOf.id) {
      this.key = this.props.viewer.graphOf.id;
      const { graphOf } = this.props.viewer;
      let people = [];
      if (graphOf.key === REPORT_CASES.GROUPS) {
        const group = this.props.viewer[graphOf.key].find((group) => group.id === graphOf.id);
        people = group ? group.members : [];
      } else {
        people = this.props.viewer[graphOf.key].members;
      }
      this.setState({ people });
    }

    if (splitParams(prevProps.location.pathname) !== splitParams(location.pathname)) {
      this.resetPeopleTargets();
    }
  }

  resetPeopleTargets = () => {
    this.props.resetPeopleTargets();
  };

  toggleSidebar = () => {
    this.setState((prevState, _props) => {
      return { sidebarOpened: !prevState.sidebarOpened };
    });
  };

  openPopup = () => {
    this.props.openFirmPopup(true);
  };

  render() {
    const {
      viewer: { reports },
      match: { url },
      activePath,
    } = this.props;
    // don't show anything if the user doesn't have access ...
    return (
      <aside className={`ai ${this.state.sidebarOpened ? 'expanded' : ''}`}>
        <div className="expando" onClick={this.toggleSidebar} />
        <div className="col-wrap">
          {reports.targets && (
            <nav className="ai firm pipeline active">
              <header>
                <span className="subhead">Pipeline Reports</span>
              </header>
              <ReportTypes
                reports={reports.targets}
                url={url}
                activeReport={activePath === 'people' ? '' : activePath}
              />
            </nav>
          )}
          {reports.trainer && (
            <nav className="ai firm pipeline active">
              <header>
                <span className="subhead">Trainer Reports</span>
              </header>
              <ReportTypes reports={reports.trainer} url={url} activeReport={activePath} />
            </nav>
          )}
        </div>
      </aside>
    );
  }
}

// map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  resetPeopleTargets: () => dispatch(resetPeopleTargets()),
  // openPopup: () => dispatch(openPopup())
});

// map state to props
const mapStateToProps = (state) => ({
  viewer: state.viewer,
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
