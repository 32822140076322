import React from 'react';

import icons from '../../../assets/img/icons.svg';
import { ROUTES_PATH } from '../../../constant';
import history from '../../../helpers/history';

/**
 * @description This component contain the detail of the lessons
 * @param {string} $id - lesson id
 * @param {string} $title - lesson title
 * @param {string} $description - lesson description
 * @param {string} $duration - lesson duration
 * @param {object} $watched - {done, percent, seconds, when}
 * @param {string} $latest_watched - yes/no
 */

// which icon to show ... "latest watched" takes precedence over "done"
export const BlogIcon = ({ latest_watched, watched = {} }) => {
  switch (true) {
    case latest_watched === 'yes' && watched.done === 'yes':
    case latest_watched === 'yes':
      return (
        <span className="indicator">
          <svg className="icon">
            <use xlinkHref={`${icons}#bookmark`} />
          </svg>
        </span>
      );
    case watched.done === 'yes':
      return (
        <span className="indicator">
          <svg className="icon">
            <use xlinkHref={`${icons}#task-done`} />
          </svg>
        </span>
      );
    default:
      return null;
  }
};

const Blog = ({ id, title, description, duration, watched, worksheet, latest_watched, url }) => {
  const handleLessonClick = () => {
    window.open(`${url}`, '_blank');
    if (window.location.hash === '#/assets/podcasts') {
      history.push({
        pathname: `${ROUTES_PATH.INSIGHTSPODCASTS}`,
      });
    } else {
      history.push({
        pathname: `${ROUTES_PATH.INSIGHTSBLOG}`,
      });
    }
  };

  return (
    <li
      className=""
      key={id}
      id={id}
      data-key={id}
      data-handle="bdoqfapuqd"
      data-title="Orientation"
      data-viewed="false"
      onClick={handleLessonClick}
      href={url}
    >
      <article
        // className={`lesson ${latest_watched === 'yes' ? 'bookmark' : ''} ${watched.done === 'yes' ? 'watched' : ''}`}
        data-bucket="lesson"
      >
        {/* <h1 style={{'--percent-watched': `${watched.percent}%`}}> */}
        <h1>
          <BlogIcon latest_watched={latest_watched} watched={watched} />
          <p>{title}</p>
        </h1>
        <div className="description">{description}</div>
        <div className="tags">
          {duration && (
            <span className="tag">
              <svg className="icon">
                <use xlinkHref={`${icons}#clock`} />
              </svg>
              {duration}
            </span>
          )}
          {worksheet && <span className="tag">worksheet</span>}
        </div>
        {/* {watched.when !== '0' &&
          <time className="watched" dateTime={watched.when}>
            {moment(watched.when).format(DueDateFormatToDisplay)}
          </time>} */}
      </article>
    </li>
  );
};

export default React.memo(Blog);
