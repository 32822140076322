import { getStanding, getTargetFromLocalDb, getAllStatusTargets } from '../../services/targets/dashboard.service';
import {
  LOAD_TARGET_DATA,
  LOAD_STANDING,
  SEARCH,
  MESSAGES,
  sharedStatus,
  TIMEZONE,
  ROUTES_PATH,
  GET_TARGET_NOTES,
  GET_TILES_NOTES,
  ADD_TARGET_NOTE,
  EDIT_TARGET_NOTE,
  TRACK_TARGET_NOTE,
} from '../../constant';
import { getUsersHUD } from '../../actions';
import { insertDocs, deleteDoc, updateATile } from '../../helpers/dbStorage';
import { store } from '../../helpers/store';
import { getToken, isOnline } from '../../helpers/sessionManager';
import toaster from '../../services/shared/toaster.service';
import { listenClearOfflineQueue } from '../../services/targets/updateOfflineQueue.service';
import { getTileNotes, getTargetNotes, addTargetNote, editTargetNote } from '../../services/targets/dashboard.service';
import { hideLoader, showLoader } from '../';
import * as moment from 'moment';
import 'moment-timezone';
import { unionBy } from 'lodash';
import { callHud } from './hud.action';
import history from '../../helpers/history';

let apicalledTime;
/**
 * function to compare and insert when there is a revoked and rejected target
 * @param {*} res
 */
const compareAndInsert = (res) => {
  typeof res.map === 'function' &&
    res.map((item) => {
      if (item.shared === sharedStatus.rejected || item.shared === sharedStatus.revoked) {
        deleteDoc(item.id);
      } else {
        updateATile(item, item.id);
      }
    });
};

const handleRefresh = () => (dispatch) => {
  const refresh = window.refresh;
  if (refresh !== 1 || !isOnline()) {
    dispatch(hideLoader());
  } else {
    window.refresh = 0;
  }
};

/**
 * get { active, archive, completed } tiles (targets) from local db to view
 */
export const getAllTargets = () => async (dispatch) => {
  try {
    const localPromise = await getTargetFromLocalDb();
    if (localPromise) {
      dispatch({
        type: LOAD_TARGET_DATA,
        payload: localPromise.docs,
      });
      // if reloaded don't hide loader// loader will be hidden in api calls
      dispatch(handleRefresh());
    }
  } catch (error) {
    window.refresh = 0;
    dispatch(hideLoader());
  }
};

export const getAllStanding = () => (dispatch) => {
  return getStanding().then((response) => {
    dispatch({
      type: LOAD_STANDING,
      payload: response,
    });
  });
};
export const getSearchedTargets = (keyword) => (dispatch) => {
  dispatch({
    type: SEARCH,
    keyword,
  });
};
export const clearSearch = () => (dispatch) => {
  const keyword = '';
  dispatch(getSearchedTargets(keyword));
};
export const dispatchTiles = (resData, cb) => (dispatch) => {
  dispatch({
    type: LOAD_TARGET_DATA,
    payload: resData,
  });
  cb && cb();
  dispatch(hideLoader());
  window.refresh = 0;
};

const filterActiveTarget = (response, alltiles = false, fromLogin = false) => {
  fromLogin ? insertDocs(response) : compareAndInsert(response);
  let currentTargets = [];
  if (!alltiles) {
    currentTargets = store.getState().dashBoard.targets;
  }
  let resData = unionBy(response, currentTargets, 'id');
  // this is filter for dobule check if some of current targets are rejected for revoked from some other device
  resData = resData.filter((item) => {
    return !(item.shared === sharedStatus.rejected || item.shared === sharedStatus.revoked);
  });
  return resData;
};

/**
 * get from server and save all tiles (targets) to local db
 */
export const getAndSaveTargets = (fromLogin, cb) => (dispatch) => {
  if (fromLogin) {
    apicalledTime = '';
  }
  dispatch(showLoader());
  listenClearOfflineQueue().then(() => {
    getAllStatusTargets(apicalledTime).then(
      (response) => {
        dispatch(callHud());
        let resData = response;
        if (response) {
          if (fromLogin) {
            resData = resData.filter((item) => {
              return !(item.shared === sharedStatus.rejected || item.shared === sharedStatus.revoked);
            });
            resData = filterActiveTarget(resData, true, fromLogin);
          } else {
            resData = filterActiveTarget(response, true);
          }
          apicalledTime = moment().tz(TIMEZONE).format('YYYY-MM-DD');
          dispatch(dispatchTiles(resData, cb));
        }
      },
      (err) => {
        if (!err.response && err.message === MESSAGES.NETWORKERR) {
          dispatch(getAllTargets());
        }
      },
    );
  });
};

/**
 * call the api to get all three types of tiles
 */
export const getAllTiles =
  (fromLogin = false) =>
  (dispatch) => {
    if (getToken()) {
      dispatch(getAndSaveTargets(fromLogin));
    }
  };

export const onTargetRefresh = (status) => (dispatch) => {
  if (!status) {
    status = 'active';
  }
  if (getToken()) {
    dispatch(getUsersHUD());
  }
  return new Promise((resolve, rejected) => {
    return listenClearOfflineQueue().then(() => {
      if (getToken()) {
        return getAllStatusTargets('').then(
          (response) => {
            apicalledTime = moment().tz(TIMEZONE).format('YYYY-MM-DD');
            if (response.length > 0) {
              const mergedTargets = filterActiveTarget(response, status);
              dispatch(dispatchTiles(mergedTargets));
              resolve();
            } else {
              resolve();
            }
          },
          (error) => {
            if (error.message.toLowerCase().indexOf(MESSAGES.NETWORKERR.toLowerCase()) > -1) {
              toaster.error(MESSAGES.NOINTERNET);
            } else {
              toaster.error(MESSAGES.UnknownError);
            }
            resolve();
          },
        );
      } else {
        rejected();
      }
    });
  });
};

export const getTargetsBasedOnTileID = (id) => (dispatch) => {
  dispatch(showLoader());
  listenClearOfflineQueue().then(() => {
    getAllStatusTargets(apicalledTime).then(
      (response) => {
        let resData = response;
        if (response) {
          dispatch(hideLoader());
          let tile = response.find((target) => id === target.id);
          if (tile) {
            resData = response.filter((item) => {
              return (
                !(item.shared === sharedStatus.rejected || item.shared === sharedStatus.revoked) &&
                tile.status === item.status
              );
            });
            dispatch(dispatchTiles(resData));
          } else {
            history.push(ROUTES_PATH.DASHBOARD + '/active');
            toaster.error('No target found');
          }
        }
      },
      (err) => {
        if (!err.response && err.message === MESSAGES.NETWORKERR) {
          dispatch(getAllTargets());
        }
      },
    );
  });
};

export const getTileNotesData = (target_id) => async (dispatch) => {
  const response = await getTileNotes(target_id);
  dispatch({
    type: GET_TILES_NOTES,
    payload: response,
  });
};

export const getTargetNotesData = (target_id) => async (dispatch) => {
  const response = await getTargetNotes(target_id);
  dispatch({
    type: GET_TARGET_NOTES,
    payload: response,
  });
};

export const addTargetNoteData =
  ({ targetId: target_id, note, token }) =>
  async (dispatch) => {
    const response = await addTargetNote(target_id, note, token);
    dispatch({
      type: ADD_TARGET_NOTE,
      payload: response,
    });
  };

export const editTargetNoteData = (id, note) => async (dispatch) => {
  const response = await editTargetNote(id, note);
  dispatch({
    type: EDIT_TARGET_NOTE,
    payload: response,
  });
};

export const trackTargetNote = () => async (dispatch) => {
  dispatch({
    type: TRACK_TARGET_NOTE,
  });
};
