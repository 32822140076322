export const API_URLS = {
  TILES: 'v1/tiles',
  STANDING: 'v1/user/standing',
  LOGIN: 'v1/user/login',
  LOGIN_SSO: 'v1/user/login/sso',
  LOGOUT: 'v1/user/logout',
  VALIDATETOKEN: 'jwt-auth/v1/token/validate',
  FIRMS: 'v1/firms/companies',
  TASKS: 'v1/tasks',
  USERS: 'v1/users',
  SHAREWITH: '?action=share&with=',
  OVERLAPS: '/overlaps',
  SHARE: 'v1/shares',
  EMPTY_TRASH: 'v1/tiles/empty_trash',
  REVOKE: '?action=revoke',
  REJECT: '?action=reject',
  ACCEPT: '?action=accept',
  RESETPASSWORD: 'v1/user/reset_password',
  HUD: 'v1/user/hud',
  CHANGEPWD: 'v1/user/new_password',
  INSIGHTS: 'v1/insights',
  USER: 'v1/user',
  VIEWINSIGHT: '?action=view',
  NOTIFICATIONREGISTER: '/register_device',
  NEWPASSWORD: 'v1/user/choose_password',
  SETYTDREVENUE: '/v1/user/set_revenue',
  SETTING: '/v1/user/setting',
  SWITCH: '/v1/user/switch?user_id=',
  LESSONS: '/v1/lessons/',
  WORKSHEETS: '/v1/worksheets/',
  RESOURCES: '/v1/resources/',
  VIEWER: '/v1/user/viewer',
  REPORT: '/report',
  ORG: '/v1/firms/',
  GROUPS: '/v1/groups/',
  CHARTS: '/charts',
  HIGHLIGHTS: '/highlights',
  TARGETS: '/targets',
  CLIENTS: '/clients',
  USER_CHALLENGE: 'v1/user/challenge',
  PROGRESS: '/progress',
  WORKSHEET: '/worksheets',
  KPIDASHBOARD: 'v1/viewer/dashboard',
  NOTES: 'v1/notes',
  INSIGHTCONTENT: 'v1/contents',
};
export const API_METHODS = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
};

export const API_PARAMS = {
  ACTIVE: 'active',
  LOST: 'lost',
  ARCHIVED: 'archived',
  COMPLETED: 'completed',
  DELETED: 'deleted',
  NEW: 'new',
  VIEWED: 'viewed',
  LESSONS: 'lessons',
  WORKSHEETS: 'worksheets',
  RESOURCES: 'resources',
  TRANSCRIPT: 'transcript',
  PIPELINE: 'pipeline',
  TRAINER: 'trainer',
  PLANNER: 'planner',
  EXTERNAL: 'extenal',
  FIRMCONTENT: 'firmcontent',
  BLOGCONTENT: 'documents',
  PODCAST: 'podcasts',
  // VIDEO: 'video',
};

export const LESSON_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  NOT_COMPLETED: 'NOT_COMPLETED',
  COMPLETED: 'COMPLETED',
  WORKSHEET: 'WORKSHEET',
  NO_WORKSHEET: 'NO_WORKSHEET',
};

export const PODCAST_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  NOT_COMPLETED: 'NOT_COMPLETED',
  COMPLETED: 'COMPLETED',
  WORKSHEET: 'WORKSHEET',
  NO_WORKSHEET: 'NO_WORKSHEET',
};

export const VIDEO_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  NOT_COMPLETED: 'NOT_COMPLETED',
  COMPLETED: 'COMPLETED',
  WORKSHEET: 'WORKSHEET',
  NO_WORKSHEET: 'NO_WORKSHEET',
};

export const BLOG_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  NOT_COMPLETED: 'NOT_COMPLETED',
  COMPLETED: 'COMPLETED',
  WORKSHEET: 'WORKSHEET',
  NO_WORKSHEET: 'NO_WORKSHEET',
};

export const API_STATUS_CODE = {
  FORBIDDEN: 403,
  DATABASE_CLOSE: 11,
  NOTFOUND: 404,
  UNAUTHORIZED: 401,
  EXPIRETOKEN: 403,
};
