import { LOAD_INSIGHTS, MARK_INSIGHT_VIEWED_COMMIT, MARK_INSIGHT_VIEWED_REQUEST } from '../../constant';
import { changeStatusOfInsights } from '../../services/insights/insight.service';

const insightDashboard = (state = { insights: [] }, action) => {
  switch (action.type) {
    case LOAD_INSIGHTS:
      return {
        insights: action.payload,
      };
    case MARK_INSIGHT_VIEWED_COMMIT:
    case MARK_INSIGHT_VIEWED_REQUEST: {
      const tile = changeStatusOfInsights(action.payload, action.payload.id);
      return {
        insights: [
          ...state.insights.map((insight) => {
            if (insight.id === tile) {
              return tile;
            }
            return insight;
          }),
        ],
      };
    }
    default:
      return state;
  }
};

export default insightDashboard;
