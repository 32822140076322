import React, { useState, useEffect, useRef } from 'react';
import {
  addMonths,
  addDays,
  addQuarters,
  format,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
} from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import icons from '../../../assets/img/icons.svg';
import FirmReportHeader from '../sideBar/FirmReportHeader.component';
import { Datepicker } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import * as moment from 'moment';
import { saveDateRangePreset } from '../../../helpers/dbStorage';
import { gTag } from '../../../helpers';

// define core dates so we can use them as a basis
const yesterday = addDays(new Date(), -1);

// create dates to be used in ranges
const defineds = {
  startOfLastWeek: startOfWeek(addDays(yesterday, -7)),
  endOfLastWeek: endOfWeek(addDays(yesterday, -7)),
  startOfYesterday: startOfDay(yesterday),
  endOfYesterday: endOfDay(yesterday),
  startOfLastMonth: startOfMonth(addMonths(yesterday, -1)),
  endOfLastMonth: endOfMonth(addMonths(yesterday, -1)),
  startOfQuarter: startOfQuarter(addQuarters(yesterday, -1)),
  endOfQuarter: endOfQuarter(addQuarters(yesterday, -1)),
  startOfLast7Days: startOfDay(addDays(yesterday, -7)),
  startOfLast30Days: startOfDay(addDays(yesterday, -30)),
  startOfLast60Days: startOfDay(addDays(yesterday, -60)),
  startOfLast90Days: startOfDay(addDays(yesterday, -90)),
  startOfLast365Days: startOfDay(addDays(yesterday, -365)),
};

const presetValues = ['last-30', 'last-60', 'last-90', 'last-month', 'last-quarter', 'last-year'];

/**
 * mobiscroll based daterange component
 * @param {*} props
 */
const DateRange = (props) => {
  const { selectionRange, activeFirmGroupInfo, openFirmPopup, reportParamsView, preset } = props;
  // mobiscroll's value
  const [myValue, setValue] = useState(null);
  // whether mobiscroll is showing or not
  const [openPicker, setOpenPicker] = useState(false);

  // reference to the mobiscroll date range picker instance
  const dateRangeButton = useRef();
  // reference to the mobiscroll component itself
  const mobiref = useRef();

  useEffect(() => {
    if (presetValues.includes(preset)) {
      let element = document.getElementById(preset);
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    setValue([selectionRange.startDate, selectionRange.endDate]);
  }, [selectionRange.startDate, selectionRange.endDate, preset]);

  // handle date range selection
  const handleRangeSelect = (e) => {
    props.handleSelect(e);
    hidePicker();
  };

  // handle date preset value
  const handlePreset = (preset) => {
    saveDateRangePreset(preset);
    gTag('Date-Range', preset);
  };

  // handle preset value for selection date range
  const handleClose = (value) => {
    if (
      moment(value[0]).format('MM/DD/YYYY') !== moment(selectionRange.startDate).format('MM/DD/YYYY') ||
      moment(value[1]).format('MM/DD/YYYY') !== moment(selectionRange.endDate).format('MM/DD/YYYY')
    ) {
      handlePreset('selectionrange');
    }
  };

  // show the date range picker
  const showPicker = () => {
    setOpenPicker(true);
    // document.getElementById('date-range-val').click();
  };

  // hide the date range picker
  const hidePicker = () => {
    setOpenPicker(false);
  };

  // show the firm orgchart popup
  const showFirmPopup = () => {
    openFirmPopup(true);
  };

  /************* IMPLEMENTATION NOTES **************

  Opened/closed details element:
   * The app needs to remember the status of the details element.
   * It should to start open.
   * But once a user collapses it, it should be collapsed on all other reports.
   * If the user then opens it again, it needs to be open on all other reports.
   * The details/summary element is open if it has an "open" attribute.

  Inside each .param, there should always be one .active element.

  For the VIEW parameter:
   * The .selection button should always be .active because there will always be a value there.

  For the DATE RANGE parameter:
   * If the user clicks on a preset date range, that preset button should be .active and should be scrolled into view.
   * If the user clicks on the date range button and changes the date, the .selection.date-range element should be .active.
   * The app should remember the last selection.

  */

  // if year is the same for both start and end, don't show year in the start value
  const startDateFormat =
    selectionRange.startDate.getFullYear() === selectionRange.endDate.getFullYear() ? 'MMM d' : 'MMM d, yyyy';
  const endDateFormat = 'MMM d, yyyy';

  return (
    // if it's open, set to true, otherwise, null so react completely drops the attribute
    <details
      className="report-params"
      open={reportParamsView === 'open' ? true : false}
      onToggle={props.handleReportParamsExpando}
    >
      <summary>
        <p>
          Viewing
          <b className="report-tag">
            <svg className="icon">
              <use xlinkHref={`${icons}#org-${activeFirmGroupInfo.type}`} />
            </svg>
            {activeFirmGroupInfo.name}
          </b>
          between
          <b className="report-tag">
            <span className="start">{format(selectionRange.startDate, startDateFormat)}</span> →{' '}
            <span className="end">{format(selectionRange.endDate, endDateFormat)}</span>
          </b>
        </p>
      </summary>
      <div className="param org-view">
        <label>
          <span>View</span>
          <small>Select the Firm, a Group, or one User’s Pipeline.</small>
        </label>
        <div className="input-wrapper">
          <div className="value" onClick={() => showFirmPopup()}>
            <div className="selection orgchart active">
              <FirmReportHeader firmHeaderTitle={activeFirmGroupInfo} />
              <div className="arrow">
                <div className="caret-wrapper">
                  <div className="caret" />
                </div>
              </div>
            </div>
          </div>
          <div className="presets">
            <small>
              The
              <b className="report-tag">
                <svg className="icon">
                  <use xlinkHref={`${icons}#org-org`} />
                </svg>
                Firm
              </b>
              view shows Groups. The
              <b className="report-tag">
                <svg className="icon">
                  <use xlinkHref={`${icons}#org-group`} />
                </svg>
                Group
              </b>
              view shows Users in that Group.
            </small>
          </div>
        </div>
      </div>
      <div className="param dates">
        <label>
          <span>Date Range</span>
          <small>Filter all information on this report to activity that took place during the selected dates.</small>
        </label>
        <div className="input-wrapper">
          <div className="value">
            <div
              className={`selection date-range ${preset === 'selectionrange' ? 'active' : ''}`}
              ref={dateRangeButton}
              onClick={showPicker}
            >
              <span className="icon">
                <svg className="icon">
                  <use xlinkHref={`${icons}#calendar`} />
                </svg>
              </span>
              <div className="range">
                <span className="start">{format(selectionRange.startDate, startDateFormat)}</span>
                <span className="end">{format(selectionRange.endDate, endDateFormat)}</span>
              </div>
              <div className="arrow">
                <div className="caret-wrapper">
                  <div className="caret"></div>
                </div>
              </div>
            </div>
            <Datepicker
              ref={mobiref}
              controls={['calendar']}
              select="range"
              anchor={dateRangeButton.current}
              display="bubble"
              animate="pop"
              touchUi={true}
              buttons={[
                'cancel',
                {
                  text: 'Apply This Range',
                  cssClass: 'btn submit',
                  handler: 'set',
                },
              ]}
              inputComponent="input"
              inputProps={{
                type: 'hidden',
                id: 'date-range-val',
              }}
              isOpen={openPicker}
              showOnClick={true}
              showOnFocus={true}
              onClose={(event) => {
                handleClose(event.value);
              }}
              onChange={handleRangeSelect}
              onCancel={hidePicker}
              onSet={hidePicker}
              value={myValue}
              min={new Date('2018-01-01')}
              max={yesterday}
              responsive={{
                xsmall: {
                  display: 'bubble',
                  pages: 1,
                },
                medium: {
                  display: 'bubble',
                  pages: 2,
                },
              }}
            />
          </div>
          <div className="presets" ref={props.dateRangePresetsRef} onScroll={props.dateRangePresetsScrollHandler}>
            <div className="scroller">
              <ul>
                <li>
                  <a
                    className={`btn ${preset === 'last-30' && 'active'}`}
                    id="last-30"
                    data-range="last-30"
                    onClick={() => {
                      handleRangeSelect({
                        value: [defineds.startOfLast30Days, defineds.endOfYesterday],
                      });
                      handlePreset('last-30');
                    }}
                    href={undefined}
                  >
                    Last 30 Days
                  </a>
                </li>
                <li>
                  <a
                    className={`btn ${preset === 'last-60' && 'active'}`}
                    id="last-60"
                    data-range="last-60"
                    onClick={() => {
                      handleRangeSelect({
                        value: [defineds.startOfLast60Days, defineds.endOfYesterday],
                      });
                      handlePreset('last-60');
                    }}
                    href={undefined}
                  >
                    Last 60 Days
                  </a>
                </li>
                <li>
                  <a
                    className={`btn ${preset === 'last-90' && 'active'}`}
                    id="last-90"
                    data-range="last-90"
                    onClick={() => {
                      handleRangeSelect({
                        value: [defineds.startOfLast90Days, defineds.endOfYesterday],
                      });
                      handlePreset('last-90');
                    }}
                    href={undefined}
                  >
                    Last 90 Days
                  </a>
                </li>
                <li>
                  <a
                    className={`btn ${preset === 'last-month' && 'active'}`}
                    id="last-month"
                    data-range="last-month"
                    onClick={() => {
                      handleRangeSelect({
                        value: [defineds.startOfLastMonth, defineds.endOfLastMonth],
                      });
                      handlePreset('last-month');
                    }}
                    href={undefined}
                  >
                    Last Month
                  </a>
                </li>
                <li>
                  <a
                    className={`btn ${preset === 'last-quarter' && 'active'}`}
                    id="last-quarter"
                    data-range="last-quarter"
                    onClick={() => {
                      handleRangeSelect({
                        value: [defineds.startOfQuarter, defineds.endOfQuarter],
                      });
                      handlePreset('last-quarter');
                    }}
                    href={undefined}
                  >
                    Last Quarter
                  </a>
                </li>
                <li>
                  <a
                    className={`btn ${preset === 'last-year' && 'active'}`}
                    id="last-year"
                    data-range="last-year"
                    onClick={() => {
                      handleRangeSelect({
                        value: [defineds.startOfLast365Days, defineds.endOfYesterday],
                      });
                      handlePreset('last-year');
                    }}
                    href={undefined}
                  >
                    Last Year
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </details>
  );
};
export default DateRange;
