import { addDays, endOfDay, startOfDay } from 'date-fns';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { DateRange, Report } from '../../../components';
import { getDateRange, getReportParamsView, saveDateRange, saveReportParamsView } from '../../../helpers/dbStorage';
import Clients from './Pipeline/Clients.container';
import Highlight from './Pipeline/Highlight.container';
import Overlaps from './Pipeline/Overlaps.container';
import Overview from './Pipeline/Overview.container';
import Targets from './Pipeline/Targets.container';
import RPIDashboard from './rpi-dashboard.container';

import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { DashBoardContainer, Progress, TargetDetailContainer, Worksheets } from '../..';
import icons from '../../../assets/img/icons.svg';
import UserWorksheet from '../../../components/trainer/listing/reports/UserWorksheet.component';
import { API_URLS, ROUTES_PATH } from '../../../constant';
import { getAllowedReportPath } from '../../../helpers';
import history from '../../../helpers/history';
import SideBar from './SideBar.container';

const _ids = {
  people: 'embedded-targets',
  overview: 'firm-overview',
  highlight: 'firm-highlights',
  targets: 'firm-targets',
  clients: 'firm-clients',
  overlaps: 'firm-overlaps',
};

const withoutDateRange = ['clients', 'overlaps', 'progress', 'worksheets', 'user_worksheets'];

/**
 * @description pipeline and trainer reports routes wrapper
 */
class Mgmt extends Component {
  constructor(props) {
    super(props);

    // define yesterday
    const yesterday = endOfDay(addDays(new Date(), -1));

    // if we don't have a min, allow them to go back 30 days
    const minDate = props.minDate ? startOfDay(new Date(props.minDate)) : startOfDay(addDays(new Date(), -30));

    // if we don't have a max, go with yesterday
    const maxDate = props.maxDate ? endOfDay(new Date(props.maxDate)) : yesterday;

    // determine if the params should start open or closed
    var paramsView = getReportParamsView();

    this.state = {
      groupOrFirmId: '',
      group: false,
      minDate: minDate,
      maxDate: maxDate,
      isPeopleTarget: false,
      uid: '',
      reportParamsView: paramsView,
    };

    const res = getDateRange();

    if (!res.dateRange) {
      const selectionRange = {
        startDate: startOfDay(addDays(yesterday, -30)),
        endDate: yesterday,
        key: 'selection',
      };
      this.state.selectionRangeModal = selectionRange;
      this.state.selectionRange = selectionRange;
      saveDateRange(selectionRange);
    } else {
      const selectionRangeData = {
        startDate: new Date(res.dateRange.startDate),
        endDate: new Date(res.dateRange.endDate),
        key: 'selection',
      };
      this.state.selectionRangeModal = selectionRangeData;
      this.state.selectionRange = selectionRangeData;
    }

    // pointer to the date range presets section so we can control left/right scroll indicators
    // this element is defined in DateRange.component
    this.dateRangePresets = React.createRef();
  }

  async componentDidMount() {
    const {
      location: { pathname },
    } = this.props;

    if (!isEmpty(this.props.viewer.reports) && pathname === '/viewer/pipeline') {
      /** redirect to either targets or trainer reports */
      history.push(getAllowedReportPath(this.props.viewer));
    }
    if (pathname.includes('user_worksheets')) {
      // get uid from url at last index
      const uid = pathname.split('/')[pathname.split('/').length - 1];
      this.setState({ isPeopleTarget: true, uid });
    }
    // pointer to the enclosing parent of the presets scroller
    this.handlePresetsScroll();
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
    } = this.props;
    if (prevProps.viewer.reports !== this.props.viewer.reports && pathname === '/viewer/pipeline') {
      /** redirect to either targets or trainer reports */
      history.push(getAllowedReportPath(this.props.viewer));
    }

    // make sure if the window resizes, we handle the scroll left/right indicator
    this.handlePresetsScroll();
  }

  handleSelect = (item) => {
    const selectionRanges = {
      startDate: item.value[0],
      endDate: item.value[1],
      key: 'selection',
    };
    this.setState({ selectionRangeModal: selectionRanges });
    this.setState({ selectionRange: selectionRanges });
    saveDateRange(selectionRanges);
  };

  // two values returned are "open" and "closed"
  handleReportParamsExpando = (e) => {
    const detailsElm = e.target; // the onToggle is on the details element, so it will always get the event
    const openAtt = detailsElm.getAttribute('open');

    // null means there is no attribute, which means the details element is closed
    const view = openAtt === null ? 'closed' : 'open';

    // store the new value in state and local db
    this.setState({ reportParamsView: view });
    saveReportParamsView(view);
  };

  // add the "off-left" and/or "off-right" classes to the presets container on scroll
  handlePresetsScroll = () => {
    let presetsElm = this.dateRangePresets.current;

    if (presetsElm) {
      let scrollElm = presetsElm.getElementsByTagName('div')[0];
      let optionsElm = presetsElm.getElementsByTagName('ul')[0];

      // if the width of the options is cut off on the right
      if (optionsElm.offsetWidth - scrollElm.scrollLeft > presetsElm.offsetWidth) {
        presetsElm.classList.add('off-right');
      } else {
        presetsElm.classList.remove('off-right');
      }

      // if the width of the options is off the left
      if (scrollElm.scrollLeft > 0) {
        presetsElm.classList.add('off-left');
      } else {
        presetsElm.classList.remove('off-left');
      }
    }
  };

  render() {
    const { match, location, openFirmPopup, viewer, reports, loading: _loading, tab } = this.props;
    const { isPeopleTarget, uid, userData: _userData } = this.state;
    let idlocal = location.pathname.split('/')[3];
    let path = location.pathname.split('/')[3];

    const activeFirmGroupInfo = [...viewer.groups, viewer.org].find((x) => x.id === viewer.graphOf.id) || {
      name: '',
      type: 'org',
    };

    // flatten reports so we can use the report name and intro from the json payload
    let reportList = {};
    Object.keys(reports).forEach(function (key) {
      let inner = Object.keys(reports[key]);
      inner.forEach(function (k) {
        reportList[k] = reports[key][k];
      });
    });

    // this report
    let localReport = reportList[idlocal];
    let userName = localStorage.getItem('userName');
    const res = getDateRange();
    return (
      <>
        {tab == 'overview' ? (
          <SideBar match={match} activePath={idlocal} location={location} openFirmPopup={openFirmPopup} />
        ) : null}
        <main>
          <div id={idlocal} className="active mgmt-report">
            {tab == 'overview' ? (
              <div>
                {path === 'user_worksheets' && (
                  <>
                    <button
                      className="go-back"
                      onClick={() => {
                        localStorage.removeItem('user_name');
                        history.goBack();
                      }}
                    >
                      <span className="icon-wrapper">
                        <svg className="icon">
                          <use xlinkHref={`${icons}#button-cancel`} />
                        </svg>
                      </span>
                      <small>Back</small>
                      <span className="user-name">{userName}</span>
                    </button>
                  </>
                )}
                {idlocal === 'people' ? (
                  <> </>
                ) : (
                  <>
                    <header className="intro">
                      <div className="title">
                        <h2>{localReport && localReport.name}</h2>
                        <p>{localReport && localReport.intro}</p>
                        <Report
                          viewer={true}
                          activeReport={idlocal}
                          selectionRange={this.state.selectionRange}
                          isPeopleTarget={isPeopleTarget}
                          uid={uid}
                        />
                      </div>
                    </header>
                    {!withoutDateRange.includes(idlocal) && this.state.selectionRangeModal && reports.targets && (
                      <DateRange
                        handleSelect={this.handleSelect}
                        selectionRange={this.state.selectionRangeModal}
                        minDate={this.state.minDate}
                        maxDate={this.state.maxDate}
                        activeFirmGroupInfo={activeFirmGroupInfo}
                        openFirmPopup={openFirmPopup}
                        reportParamsView={this.state.reportParamsView}
                        preset={res.preset || 'last-30'}
                        handleReportParamsExpando={this.handleReportParamsExpando}
                        dateRangePresetsRef={this.dateRangePresets} // defined in the constructor
                        dateRangePresetsScrollHandler={this.handlePresetsScroll} // defined above
                      />
                    )}
                  </>
                )}
                <Switch>
                  <Route exact path={`${match.path}${ROUTES_PATH.OVERVIEW}`}>
                    <Overview dateRange={this.state.selectionRange} />
                  </Route>
                  <Route exact path={`${match.path}${API_URLS.HIGHLIGHTS}`}>
                    <Highlight dateRange={this.state.selectionRange} />
                  </Route>
                  <Route exact path={`${match.path}${API_URLS.TARGETS}`}>
                    <Targets dateRange={this.state.selectionRange} />
                  </Route>
                  <Route exact path={`${match.path}${API_URLS.CLIENTS}`}>
                    <Clients
                      activeFirmGroupInfo={activeFirmGroupInfo}
                      openFirmPopup={openFirmPopup}
                      reportParamsView={this.state.reportParamsView}
                      handleReportParamsExpando={this.handleReportParamsExpando}
                    />
                  </Route>
                  <Route exact path={`${match.path}${API_URLS.OVERLAPS}`}>
                    <Overlaps
                      activeFirmGroupInfo={activeFirmGroupInfo}
                      openFirmPopup={openFirmPopup}
                      reportParamsView={this.state.reportParamsView}
                      handleReportParamsExpando={this.handleReportParamsExpando}
                    />
                  </Route>
                  <Route exact path={`${match.path}${ROUTES_PATH.PROGRESS}`}>
                    <Progress />
                  </Route>
                  <Route exact path={`${match.path}${ROUTES_PATH.WORKSHEET}`}>
                    <Worksheets />
                  </Route>
                  <Route exact path={`${match.path}${ROUTES_PATH.USER_WORKSHEETS}/:userId`}>
                    <UserWorksheet />
                  </Route>
                  <Route
                    exact
                    path={`${match.path}${ROUTES_PATH.PEOPLE}/:uid`}
                    render={(props) => (
                      <DashBoardContainer {...props} isPeopleTarget dateRange={this.state.selectionRange} />
                    )}
                  />
                  <Route
                    exact
                    path={`${match.path}${ROUTES_PATH.PEOPLE}/:uid${ROUTES_PATH.TARGETDETAIL}/:targetId`}
                    render={(props) => (
                      <TargetDetailContainer {...props} isPeopleTarget dateRange={this.state.selectionRange} />
                    )}
                  />
                </Switch>
                <ReactTooltip
                  id="tip"
                  placement={'top'}
                  multiline={true}
                  getContent={(dataTip) => <span dangerouslySetInnerHTML={{ __html: dataTip }}></span>}
                  scrollHide={true}
                />
              </div>
            ) : (
              <RPIDashboard />
            )}
          </div>
        </main>
      </>
    );
  }
}

// map state to props

const mapStateToProps = (state) => ({
  viewer: state.viewer,
  loading: state.loader,
});

export default connect(mapStateToProps)(React.memo(Mgmt));
