export const ROUTES_PATH = {
  HOME: '/',
  LOGIN: '/login',
  SSO: '/login/sso',
  DASHBOARD: '/targets',
  TARGETDETAIL: '/target',
  RESETPASSWORD: '/getresetPasswordLink',
  ACCOUNT: '/account',
  INSIGHTS: '/assets',
  INSIGHTDETAIL: '/asset',
  NEWPASSWORD: '/emailConfirmed',
  SWITCH: '/switch',
  TRAINER: '/trainers',
  TRAINER_DETAIL: '/trainer',
  VIEWER: '/viewer',
  PIPELINE: '/pipeline',
  PEOPLE: '/people',
  OVERVIEW: '/overview',
  LESSON: '/lesson',
  OTP: '/otp-password',
  PROGRESS: '/progress',
  WORKSHEET: '/worksheets',
  USER_WORKSHEETS: '/user_worksheets',
  INSIGHTSBLOG: '/assets/documents',
  INSIGHTSPODCASTS: '/assets/podcasts',
};

export const ROUTES_SUB_PATH = {
  DASHBOARD: {
    ACTIVE: '/active',
    ARCHIVED: '/archived',
    COMPLETED: '/completed',
    LOST: '/lost',
  },
  INSIGHTS: {
    // NEW: '/new',
    // VIEWED: '/viewed'
    BLOG: '/documents',
    PODCASTS: '/podcasts',
    EVENTS: '/events',
    GUIDES: '/guides',
    WHITEPAPERS: '/whitepapers',
    FAQS: '/FAQs',
    VIDEOS: '/videos',
  },
  TRAINER: {
    LESSONS: '/lessons',
    WORKSHEETS: '/worksheets',
    RESOURCES: '/resources',
  },
  ACCOUNT: {
    EDIT: '/edit',
    NOTIFICATIONS: '/notifications',
    PASSWORD: '/pwd',
    ABOUT: '/about',
  },
};

export const NONAVBAR = [
  ROUTES_PATH.LOGIN,
  ROUTES_PATH.NEWPASSWORD,
  ROUTES_PATH.RESETPASSWORD,
  ROUTES_PATH.SWITCH,
  ROUTES_PATH.OTP,
];
