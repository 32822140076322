import React, { useEffect, useState } from 'react';

import { SearchBtn } from '../shared/SearchBtn';
import '../../assets/css/nav-secondary.css';
import NavTabs from './NavTabs';
import { INSIGHTS } from '../../constant/insights';

/**
 * @description This component render sub navigation menu for insights, trainer and account
 * @param {Array} $tabs - list of secondary tabs
 * @param {string} $type - pipeline, insights, trainer, viewer
 * @param {string} $status - current active tab
 * @param {function} $changeTab - update the status of the tab on click
 */
// const NavSecondary = ({type, tabs, status, openFilter, changeTab}) => (
function NavSecondary({ type, tabs, status, openFilter, changeTab, setModalType }) {
  const [showAddContent, setShowAddContent] = useState(false);

  useEffect(() => {
    setShowAddContent(type === INSIGHTS);
  }, [type]);

  return (
    <div id="secondary-container" className={`show nav-${type}`}>
      {/* Secondary Navigation - Targets */}
      <nav id={`nav-${type}`} className="app nav-secondary">
        <NavTabs
          tabs={tabs}
          getData={(status) => changeTab(status)}
          status={status}
          openFilter={openFilter}
          SearchBtn={SearchBtn}
        />
      </nav>
    </div>
  );
}

export default NavSecondary;
